import styled from 'styled-components';
import { Button, Grid } from '@mui/material';
const StyledLoginGrid = styled(Grid)`
    background: #FFFFFF;
    display: flex;
    justify-content: space-between;
    height: 100vh;
    overflow: hidden;
    .left-section, .right-section{
        width: 50%;
    }
    .right-section {
        position: relative;
        background-image: url('./login-hero.jpg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
`;

const StyledGoogleLoginButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    p {
        margin: 0;
        color: #FFF;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        text-transform: capitalize;
    }
`;
const StyledLoginButton = styled(Button)`
    width: 360px;
    color: #FFFFFF !important; //used to overritten the default style of button mui
    background: rgba(37, 89, 195, 0.9) !important; //used to overritten the default style of button mui
    fontSize: 16px;
    textTransform: none;
    lineHeight: 24px;
    height: 44px;
`;
const StyledInput = styled.input`
  padding: 15px;
  width: 360px;
  border: 2px solid #ccc;
  border-radius: 10px; /* Adjust the radius to control the curvature */
  margin-bottom: 10px; /* Add margin to create space between fields */
  box-sizing: border-box; /* Ensure padding is included in width */
  font-size: 16px; /* Adjust font size */

  /* Add any other custom styles you want for the input */
`;
const StyledErrorMsg = styled.div`
    color: red; 
    margin-top:7px;
`;
const StyledMainTitleContainer = styled.div`
    &.main-title-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100vh;
    }
    .logo-container {
        margin-left: 0rem;
    }
    .login-text {
        color: #101828;
        font-family: Poppins;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 44px;
        letter-spacing: -0.56px;
    }
`;

export {
  StyledLoginGrid, StyledLoginButton,
  StyledMainTitleContainer,
  StyledErrorMsg, StyledGoogleLoginButton, StyledInput,
};
