/* eslint-disable */
import React, { useState, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Typography } from '@mui/material';
import {
  StyledLoginGrid,
  StyledMainTitleContainer,
  StyledLoginButton,
  StyledInput,
} from './Login.styled';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [loginError, setLoginError] = useState('');
  const navigate = useNavigate();
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleLoginFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setEmailError('');
    setPasswordError('');
    setLoginError('');

    if (!email) {
      setEmailError('Email is required');
    } else if (!email.endsWith('@successive.tech')) {
      setEmailError('Email should end with @successive.tech');
    }

    if (!password) {
      setPasswordError('Password is required');
    }

    try {
      if (email && email.endsWith('@successive.tech') && password) {
        const formData = {
          email,
          password,
        };
        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/user/login`, formData);
        if (data.error) {
          setLoginError(data.message);
        } else {
          localStorage.setItem('token', data.data);
          localStorage.setItem('user', email);
          navigate('/roles');
        }
      }
    } catch (error: any) {
      if (error.response) {
        if (error.response.status === 403) {
          setLoginError('Permission denied: You do not have access.');
        } else if (error.response.status === 400) {
          setLoginError('Invalid credentials.');
        } else {
          setLoginError('Something went wrong.');
        }
      } else if (error.request) {
        setLoginError('No response from server. Please try again later.');
      } else {
        setLoginError('Something went wrong.');
      }
    }
  };

  return (
    <StyledLoginGrid className="login-wrapper">
      <div className="left-section">
        <StyledMainTitleContainer className="main-title-container">
          <div className="logo-container">
            <img src="logo.png" alt="logo" />
          </div>
          <div>
            <p className="login-text">Log in</p>
            <form onSubmit={handleLoginFormSubmit}>
              <div className="form-group">
                <StyledInput
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="Email"
                />
                {emailError && (
                <Typography variant="body2" color="error">
                  {emailError}
                </Typography>
                )}
              </div>
              <div className="form-group">
                <StyledInput
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                  placeholder="Password"
                />
                {passwordError && (
                <Typography variant="body2" color="error">
                  {passwordError}
                </Typography>
                )}
              </div>
              <StyledLoginButton
                type="submit"
                variant="contained"
                style={{ textTransform: 'none' }}
              >
                Login
              </StyledLoginButton>
              {loginError && (
                <Typography variant="body2" color="error">
                  {loginError}
                </Typography>
              )}
            </form>
          </div>
        </StyledMainTitleContainer>
      </div>
      <div className="right-section" />
    </StyledLoginGrid>
  );
}

export default Login;
